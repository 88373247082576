import React, { useRef, useEffect } from 'react'
import Image1 from '../images/products_esl_01.png'
import Image2 from '../images/products_esl_02.png'
import Image3 from '../images/products_esl_03.png'
import Image4 from '../images/products_esl_04.png'

const EslHeroImage = () => {

  const image1Ref = useRef(null)
  const image2Ref = useRef(null)
  const image3Ref = useRef(null)
  const image4Ref = useRef(null)

  useEffect(() => {
    let timer1, timer2
    image1Ref.current.src = Image1
    image1Ref.current.onload = () => {
      image1Ref.current.style.opacity = 1
      image2Ref.current.style = { opacity: 1 }
      timer1 = setTimeout(() => {
        image3Ref.current.style = { opacity: 1 }
      }, 750)
      timer2 = setTimeout(() => {
        image4Ref.current.style = { opacity: 1 }
      }, 2000)
    }
    return () => {
      clearTimeout(timer1)
      clearTimeout(timer2)
    }
  })

  return (
    <div className='relative overflow-hidden'>
      <div style={{ paddingBottom: '100%' }} />
      <img
        ref={image1Ref}
        alt=''
        style={{ opacity: 0 }}
        className='absolute w-full h-full top-0 left-0 transition-opacity duration-300'
      />
      <img
        ref={image2Ref}
        src={Image2}
        alt=''
        style={{ opacity: 0, transform: 'translateY(100%)' }}
        className='absolute w-full h-full top-0 left-0 transition duration-500'
      />
      <img
        ref={image3Ref}
        src={Image3}
        alt=''
        style={{ opacity: 0, transform: 'translateY(-100%)' }}
        className='absolute w-full h-full top-0 left-0 transition duration-500'
      />
      <img
        ref={image4Ref}
        src={Image4}
        alt=''
        style={{ opacity: 0, transform: 'translateY(100%)' }}
        className='absolute w-full h-full top-0 left-0 transition duration-500'
      />
    </div>
  )
}

export default EslHeroImage
