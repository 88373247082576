import React, { useEffect } from 'react'

import Layout from '../../components/layout'
import Seo from '../../components/seo'
import ProductsSubnav from '../../components/products_subnav'
import EsTeaser from '../../components/es_teaser'
import HeaderSpacer from '../../components/header_spacer'
import VideoModal from '../../components/video_modal'
import { StaticImage } from 'gatsby-plugin-image'
import Container from '../../components/container'
import Button from '../../components/button'
import { Link as GatsbyLink } from 'gatsby'
import DemoModal from '../../components/demo_modal'
import NumberedScroll from '../../components/numbered_scroll'
import FullyLoadedImage1 from '../../images/fullyloaded_esl_Seamless.png'
import FullyLoadedImage2 from '../../images/fullyloaded_esl_Store-level-chat.png'
import FullyLoadedImage3 from '../../images/fullyloaded_esl_CRM-integration.png'
import EngagementIcon from '../../images/esl_engagement_icon.svg'
import EslHeroImage from '../../components/esl_hero_image'

const LiveChatPage = () => {

  useEffect(() => {
    let hasScrolled = false
    const observer = new IntersectionObserver(entries => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          window.addEventListener('scroll', () => {
            const target = entry.target
            const windowBottom = window.pageYOffset + window.innerHeight
            const elementTop = target.offsetTop
            const percentage = (windowBottom - elementTop) / target.offsetHeight
            target.style.setProperty('--scroll', percentage)
          })
        }
      })
      if (!hasScrolled) {
        hasScrolled = true
        window.dispatchEvent(new CustomEvent('scroll'))
      }
    })
    document.querySelectorAll('.is-animated').forEach(el => observer.observe(el))
  })

  return (
    <Layout activeNav={true}>
      <Seo
        title='Live Chat'
        description='Express Store Live is a dealer chat solution managed by Roadster’s live automotive experts. They leverage the features found within your Express Store to instantly answer customers’ questions and engage them in conversations that lead to higher quality opportunities.'
      />
      <HeaderSpacer dark />
      <ProductsSubnav />
      <div className='flex flex-col justify-center relative' style={{ minHeight: 675 }}>
        <StaticImage
          src='../../images/esl_hero_bg.jpg'
          alt=''
          placeholder='blurred'
          className='absolute inset-0'
        />
        <Container className='relative'>
          <div className='pt-4 md:pt-0 grid items-center md:gap-3 md:grid-cols-2 lg:gap-5 text-center md:text-left'>
            <div>
              <p className='text-gray uppercase font-semi-bold text-base tracking-wide leading-none'>
                Live Chat
              </p>
              <h1 className='mt-0 font-semi-bold text-4vw tracking-tight' style={{ marginLeft: '-.08em' }}>
                Express Store Live<sup className='tm font-light'>™</sup>
              </h1>
              <p className='text-base sm:text-md lg:text-lg font-light sm:leading-relaxed mb-1/2em'>
                Express Store Live is a dealer chat solution managed by Roadster’s live automotive experts. They leverage the features found within your Express Store to instantly answer customers’ questions and engage them in conversations that lead to higher quality opportunities.
              </p>
              <p className='italic text-base sm:text-base lg:text-md text-gray'>
                Express Storefront Required
              </p>
              <div className='mt-3 sm:mt-4'>
                <DemoModal>
                  <Button
                    variant='dark'
                    minWidth={true}
                    children='Request Demo'
                  />
                </DemoModal>
              </div>
            </div>
            <div>
              <EslHeroImage />
            </div>
          </div>
        </Container>
      </div>
      <div className='Section pt-0 pb-3'>
        <Container>
          <div className='grid gap-3 sm:gap-5 sm:grid-cols-2 lg:grid-cols-3 leading-relaxed'>
            <div>
              <h2 className='font-light text-xl sm:text-2xl mt-0'>
                <img
                  alt=''
                  src='https://media.roadster.com/dealersales/ESL_Icon_1.svg'
                  height='82'
                  width='66'
                  className='block mb-2'
                />
                People
              </h2>
              <p className='text-base sm:text-md mb-0'>
                Our in-house automotive experts have an average of 4 years of experience, and they know the ins and outs of your Express Store. Unlike auto-dealer-chat providers who use bots and are only incentivized to secure leads, our live-chat team provides detailed answers to customers’ questions and guides them deeper down the purchase funnel. In fact, customers who use Express Store Live are 3x more likely to complete deal-making activities like finalizing a credit application, submitting a trade-in and starting an order.
              </p>
            </div>
            <div>
              <h2 className='font-light text-xl sm:text-2xl mt-0'>
                <img
                  alt=''
                  src='https://media.roadster.com/dealersales/ESL_Icon_2.1.svg'
                  height='82'
                  width='66'
                  className='block mb-2'
                  style={{ marginLeft: '-.1em' }}
                />
                Platform
              </h2>
              <p className='text-base sm:text-md mb-0'>
                Express Store Live and your Express Store were designed to work seamlessly together. Within the live chat service, we leverage your full set of Express Store features to help customers find inventory, arrive at transactional pricing, build car deals and complete the checkout process. 
              </p>
            </div>
            <div>
              <h2 className='font-light text-xl sm:text-2xl mt-0'>
                <img
                  alt=''
                  src='https://media.roadster.com/dealersales/ESL_Icon_3.svg'
                  height='82'
                  width='66'
                  className='block mb-2'
                  style={{ marginLeft: '-.15em' }}
                />
                Performance
              </h2>
              <p className='text-base sm:text-md mb-0'>
                When we answer car buyers’ initial questions, they are more likely to complete deal-making activities. Customers who chat with us are 2.5x more likely to submit a lead in your Express Store and 3x more likely to complete a credit application. And best of all? They place vehicle orders over 3x more than customers who don’t chat with us.
              </p>
            </div>
          </div>
        </Container>
      </div>
      <VideoModal videoId='549351811'>
        <button type='button' className='Section pt-0 sm:pb-0 relative sm:text-white'>
          <div className='relative'>
            <StaticImage
              src='../../images/ESL_Video.jpg'
              alt=''
              placeholder='blurred'
            />
            <StaticImage
              alt='Play Video'
              src='../../images/icon-play.png'
              className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 sm:hidden'
              placeholder='tracedSVG'
              style={{ width: '15%' }}
            />
          </div>
          <div className='sm:absolute inset-0 flex flex-col justify-center'>
            <div>
              <Container>
                <div className='sm:flex'>
                  <div className='sm:size-1of2 md:size-3of8'>
                    <h2 className='text-xl md:text-2xl lg:text-3xl font-light'>
                      Live Automotive Experts.
                    </h2>
                    <p className='text-md leading-relaxed max-w-measure-2 mb-0'>
                      In under three minutes, learn how Express Store Live uses live automotive experts to help customers complete more deal-making activities.
                    </p>
                    <div className='sm:hidden mt-3'>
                      <Button
                        as='span'
                        variant='dark'
                        children='Watch Video'
                      />
                    </div>
                  </div>
                  <div className='hidden sm:block flex-1 relative text-white'>
                    <StaticImage
                      alt='Play Video'
                      src='../../images/icon-play.png'
                      className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 size-2of10 md:size-4of10 hover:opacity-60 transition-opacity duration-300'
                      placeholder='tracedSVG'
                    />
                  </div>
                  <div className='hidden md:block size-3of8'></div>
                </div>
              </Container>
            </div>
          </div>
        </button>
      </VideoModal>
      <div className='Section'>
        <Container size='lg'>
          <h2 className='text-2xl sm:text-3xl font-light text-center mt-0'>
            Fully Loaded
          </h2>
          <p className='text-md text-center'>
            Express Store Live comes equipped with best-in-class car dealer chat features. 
          </p>
          <NumberedScroll
            items={[
              {
                title: 'Seamless integration options',
                desc: 'Allow customers to initiate a live chat on your native website and third-party apps (at no extra charge) and seamlessly continue the conversation on your Express Store.',
                image: FullyLoadedImage1
              },
              {
                title: 'Lightning-fast response time',
                desc: 'Our automotive experts respond to customers’ queries within 30 seconds, thereby reducing the chance of customer drop-off by 84%.'
              },
              {
                title: 'Store-level chat',
                desc: 'With Express Store Live, sales agents have the ability to view and take over active conversations. They can also use it to privately communicate with the automotive expert answering the chat.',
                image: FullyLoadedImage2
              },
              {
                title: 'After-hours assistance',
                desc: 'Our live team is available to respond to customers during and after your standard business hours — including late evenings, when 30% of dealmaking activity occurs.'
              },
              {
                title: 'Click-path visibility',
                desc: 'No need to ask customers for vehicle info like other dealer chat providers do. Our automotive experts can see the entire car-buying journey and use these insights to enrich every engagement.'
              },
              {
                title: 'Chat routing',
                desc: 'If a question comes up that our team can’t answer, we’ll route it to the right person at your dealership or escalate the chat session to a designated manager.'
              },
              {
                title: 'CRM integration',
                desc: 'We’ll automatically push all of the customer, chat summary and transcript into your CRM to enrich your followup and help you sell more efficiently.',
                image: FullyLoadedImage3
              },
              {
                title: 'Rich insights',
                desc: 'Within Roadster Admin, we provide information about the car buyer’s journey, a time-saving summary of every conversation and a full transcript if you want to dig into the details.'
              }
            ]}
          />
        </Container>
      </div>
      <div className='Section Section--condensed Section--stripe pb-0 text-center'>
        <Container>
          <div className='flex justify-center mb-3 sm:mb-4'>
            <img
              alt=''
              src={EngagementIcon}
              width={59}
              height={58}
            />
          </div>
          <div className='grid sm:grid-cols-[1fr_auto_1fr] sm:leading-relaxed sm:text-left text-md md:text-lg md:font-light sm:gap-[1.5em] sm:max-w-[40em] mx-auto'>
            <span>
              Customers who chat with Express Store Live submit a trade-in
            </span>
            <span
              className='leading-none sm:-mt-[0.06em]'
              style={{
                fontSize: '6.4em'
              }}
            >
              <span className='font-semi-bold'>3.5</span>
              <span className='font-light'>X</span>
            </span>
            <span>more than those who do not chat with us</span>
          </div>
          <div className='my-4'>
            <DemoModal>
              <Button
                variant='dark'
                minWidth={true}
                children='Request Demo'
              />
            </DemoModal>
          </div>
          <StaticImage
            alt=''
            src='../../images/esl_tradechat.png'
            width={500}
            placeholder='blurred'
          />
          <div className='text-silver text-right absolute bottom-3 right-container'>
            <p class="mb-0">Data reflects 7/1/21-11/30/21</p>
          </div>
        </Container>
      </div>
      <div className='relative bg-dark-gray py-4'>
        <StaticImage
          alt=''
          src='../../images/customerstories_bg.jpg'
          placeholder='blurred'
          className='absolute inset-0'
        />
        <Container size='lg' className='relative'>
          <div className='text-white text-center'>
            <div className='font-semi-bold leading-none text-silver font-serif' style={{ fontSize: '6rem' }}>“</div>
            <div className='Testimonial' style={{ maxWidth: '30em' }}>
              With Express Store Live, Roadster’s automotive experts are an extension of our sales team.
            </div>
            <div className='mt-3 text-md'>
              Jarred Black, General Manager
            </div>
            <div className='text-md font-semi-bold'>
              Larry H. Miller Toyota Boulder
            </div>
            <div className='flex items-center leading-none mb-4 mt-5'>
              <span className='flex-1 border-t border-gray' />
              <span className='mx-2 uppercase tracking-wide text-base sm:text-md font-semi-bold'>
                More Customer Stories
              </span>
              <span className='flex-1 border-t border-gray' />
            </div>
          </div>
          <div className='grid gap-3 sm:grid-cols-2 md:grid-cols-3 sm:gap-4'>
            <GatsbyLink
              to='/customer_stories/sunautogroup'
              className='group w-full flex flex-col bg-white hover:shadow-lg transition-shadow'>
              <div className='p-2 sm:p-3'>
                <div className='font-semi-bold text-base sm:text-md mb-1/4em'>
                  Sun Automotive Group
                </div>
                <div className='text-md sm:text-lg text-gray'>
                  Automotive Advisors are the single point of contact for the entire sales process.
                </div>
              </div>
              <div className='relative mt-auto'>
                <img
                  alt=''
                  loading='lazy'
                  src='https://media.roadster.com/stories/sunautogroup/thumb.jpg'
                  className='block w-full'
                /> 
                <div className='transition-opacity opacity-0 group-hover:opacity-100 bg-darken4 absolute inset-0 flex justify-center items-center text-white'>
                  <Button
                    as='span'
                    variant='outline'
                    minWidth={true}
                    children='View Story'
                  />
                </div>
              </div>
            </GatsbyLink>
            <GatsbyLink
              to='/customer_stories/audirocklin'
              className='group w-full flex flex-col bg-white hover:shadow-lg transition-shadow'>
              <div className='p-2 sm:p-3'>
                <div className='font-semi-bold text-base sm:text-md mb-1/4em'>
                  Audi Rocklin
                </div>
                <div className='text-md sm:text-lg text-gray'>
                  Transparent customer experiences that lead to higher gross and CSI.
                </div>
              </div>
              <div className='relative mt-auto'>
                <img
                  alt=''
                  loading='lazy'
                  src='https://media.roadster.com/stories/audirocklin/thumb.jpg'
                  className='block w-full'
                /> 
                <div className='transition-opacity opacity-0 group-hover:opacity-100 bg-darken4 absolute inset-0 flex justify-center items-center text-white'>
                  <Button
                    as='span'
                    variant='outline'
                    minWidth={true}
                    children='View Story'
                  />
                </div>
              </div>
            </GatsbyLink>
            <GatsbyLink
              to='/customer_stories/longotoyota'
              className='group w-full flex flex-col bg-white hover:shadow-lg transition-shadow'>
              <div className='p-2 sm:p-3'>
                <div className='font-semi-bold text-base sm:text-md mb-1/4em'>
                  Longo Toyota
                </div>
                <div className='text-md sm:text-lg text-gray'>
                  Digitizing every step of the sales process.
                </div>
              </div>
              <div className='relative mt-auto'>
                <img
                  alt=''
                  loading='lazy'
                  src='https://media.roadster.com/stories/longotoyota/thumb.jpg'
                  className='block w-full'
                /> 
                <div className='transition-opacity opacity-0 group-hover:opacity-100 bg-darken4 absolute inset-0 flex justify-center items-center text-white'>
                  <Button
                    as='span'
                    variant='outline'
                    minWidth={true}
                    children='View Story'
                  />
                </div>
              </div>
            </GatsbyLink>
          </div>
        </Container>
      </div>
      <EsTeaser />
    </Layout>
  )
}

export default LiveChatPage
